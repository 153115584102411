import React from 'react';


const InfoPage: React.FC = () => {

  return(
    <div className="row">
      <div className="col-sm-8 py-4">
        <p>
          I created this calculator after having researched different PCP deals and failed to find a PCP
          calculator which allowed me to easily compare different deals.

          Initially I had a spreadsheet based calculator which did the job, but since I was looking into Angular (tool for building
          web sites like this) anyway this was a simple enough web application to use as an example.
    </p>

        <p>
          This calculator is somewhat opinionated when it comes to the total and annual cost in which the part
          exchange equity is not included.
    </p>

        <p>
          It is highly recommended that you under stand how a PCP deal works and what aspects of it may end up getting costly
          or you might end up agreeing to something which is not quite what you expected.
    </p>

        <p>
          Hopefully you will find some useful information in the links below.
    </p>

        <ul>
          <li>
            <a href="https://www.ft.com/content/8bd9da60-0a5d-11e7-ac5a-903b21361b43" target="_blank" rel="noopener noreferrer">Financial Times - How to understand PCP car financing deal </a>
          </li>
          <li>
            <a href="http://www.parkers.co.uk/car-finance/advice/2016/what-happens-at-the-end-of-a-pcp-deal/" target="_blank" rel="noopener noreferrer">Car finance: what happens at the end of a PCP deal?</a>
          </li>
          <li>
            <a href="http://www.financingyourcar.org.uk/car-finance/personal-contract-purchase-pcp/" target="_blank" rel="noopener noreferrer">Financing Your Car - PCP description</a>
          </li>
          <li>
            <a href="http://timharford.com/2017/06/trapsfordinosaurs/" target="_blank" rel="noopener noreferrer">Personal finance sets traps for dinosaurs</a>
          </li>
          <li>
            <a href="http://www.express.co.uk/finance/personalfinance/821102/Car-finance-PCP-explained-HP-deals" target="_blank" rel="noopener noreferrer">Car finance: Is it time to go down the PCP and HP road?</a>
          </li>
          <li>
            <a href="http://www.moneysavingexpert.com/car-finance/personal-contract-purchase" target="_blank" rel="noopener noreferrer">PCP finance explained </a>
          </li>
        </ul>

      </div>
    </div>
  );
};

export default InfoPage;
