import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from './Header';

export interface Props{
    component?: React.ComponentType;
}

export const AppPage: React.FC<Props> = (props) => {
    console.log("AppPage used");

    return (
    <Container fluid>
        <Row>
            <Col>
                <Header />
            </Col>
        </Row>
        <Row>
            <Col>
                {props.component !== undefined && <props.component />}
                {props.children}
            </Col>
        </Row>
    </Container>
    );
};