import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trash, Back, FileEarmark, Share } from "react-bootstrap-icons";
import Calculator, { PcpData } from "./Calculator";

const PcpComparePage: React.FC = () => {

  let example1: PcpData = new PcpData();
  example1.name = "Ex Skoda Kodiaq";
  example1.apr = 0;
  example1.contribution = 1000;
  example1.deposit = 3095;
  example1.price = 30950;
  example1.gfv = 12784.50;
  example1.pxGFV = 0;
  example1.pxValuation = 0;
  example1.term = 35;


  const [pcpDataList, setPcpDataList] = useState<PcpData[]>([example1]);

  function castAndSetValue(data: PcpData, fieldName: string, value: any) {
    let typeName = typeof (data[fieldName])
    switch (typeName) {
      case 'number': data[fieldName] = Number(value); break;
      case 'string': data[fieldName] = value; break;
      default:
        console.log("Unable to cast %s to %s", value, typeName);
        return value;
    }
  }

  const onDataChange = (data: PcpData, field: string, value: any) => {
    let toUpdate: PcpData | undefined = pcpDataList.find(i => i === data);
    console.log("Before update", data);
    if (toUpdate !== undefined) {
      console.log("Updating value from parent data", data);
      castAndSetValue(toUpdate, field, value);
      //Make sure list is re-created, state should be immutable
      setPcpDataList([...pcpDataList])
      console.log("After update", data);
    }
    else {
      console.log("Error, unable to find data to update for id", data);
    }
  }

  const addNewCalculator = () => {
    let newData = new PcpData();
    setPcpDataList([...pcpDataList, newData])
  }

  const removeCalculator = (toRemove: PcpData) => {
    setPcpDataList([...(pcpDataList.filter(i => i !== toRemove))]);
  }

  const cloneCalculator = (toClone: PcpData) => {
    let newData = toClone.clone();
    newData.name = newData.name + ' copy';
    setPcpDataList([...pcpDataList, newData])
  }

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12}>
            <p>
              Create multiple PCP deals on the same page and tinker with the numbers to see how they stack up against each other.
          </p>
            <Button variant="outline-primary" onClick={addNewCalculator}>
              <FileEarmark> </FileEarmark> Add New
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <br/>
          </Col>
        </Row>
        <Row>
          {pcpDataList.map((d, index) =>
            <Col key={index} sm={6} md={4}>

              <Button size="sm" variant="outline-danger" onClick={() => removeCalculator(d)}>
                <Trash> </Trash>Remove
              </Button>

              <Button size="sm" variant="outline-primary" onClick={() => cloneCalculator(d)}>
                <Back> </Back> Clone
              </Button>

              <Button size="sm" variant="outline-primary" onClick={() => cloneCalculator(d)}>
                <Share> </Share> Share
              </Button>

              <Calculator data={d} onDataChange={onDataChange} />
            </Col>)}
        </Row>
      </Container>
    </div>
  );
};

export default PcpComparePage;
