import React from 'react';
import { Card, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { InfoCircle } from 'react-bootstrap-icons';


export class CalcService {
  public pmt(rate: number, nperiod: number, pv: number, fv: number = 0, type: number = 0): number {
    if (rate === 0) {
      return -(pv + fv) / nperiod;
    }

    let pvif = Math.pow(1 + rate, nperiod);
    let pmt = rate / (pvif - 1) * -(pv * pvif + fv);

    if (type === 1) {
      pmt /= (1 + rate);
    }
    return pmt;
  }
}

export class User {
  username: string;
  roles: Array<string>;

  constructor(username: string, roles: Array<string>) {
    this.username = username;
    this.roles = roles;
  }

  hasRole(role: string): boolean {
    return this.roles.indexOf(role) >= 0;
  }
}

export class PcpData {
  price: number = 0;
  contribution: number = 0;
  gfv: number = 0;
  term: number = 0;
  apr: number = 0;
  deposit: number = 0;

  pxValuation: number = 0;
  pxGFV: number = 0;
  name: string = "";

  // For map type access
  [key: string]: any;


  clone(): PcpData {
    let retVal: PcpData = new PcpData();
    retVal.name = this.name;
    retVal.price = this.price;
    retVal.contribution = this.contribution;
    retVal.gfv = this.gfv;
    retVal.term = this.term;
    retVal.apr = this.apr;
    retVal.deposit = this.deposit;
    retVal.pxValuation = this.pxValuation;
    retVal.pxGFV = this.pxGFV;

    return retVal;
  }

  copyInto(json: any): void {
    this.name = json.name;
    this.price = json.price;
    this.contribution = json.contribution;
    this.gfv = json.gfv;
    this.term = json.term;
    this.apr = json.apr;
    this.deposit = json.deposit;
    this.pxValuation = json.pxValuation;
    this.pxGFV = json.pxGFV
  }

  getCashAmount(): number {
    return this.contribution + this.deposit + (this.pxValuation - this.pxGFV);
  }

  getFinancedAmount(): number {
    return this.price - this.getCashAmount();
  }

  getDeprecationAmount(): number {
    return this.price - this.gfv;
  }

  getMonthlyCost(): number {
    return this.pmt(
      this.apr / 100 / 12,
      this.term,
      this.getFinancedAmount(),
      -1 * this.gfv
    ) * -1;
  }

  getActualMonthlyCost(): number {
    return this.getMonthlyCost() + (this.deposit / this.term)
  }

  getYearlyCost(): number {
    return this.getTotalTermCost() / (this.term / 12);
  }

  getTotalTermCost(): number {
    return this.getMonthlyCost() * this.term
      + this.deposit;
  }

  public pmt(rate: number, nperiod: number, pv: number, fv: number = 0, type: number = 0): number {
    if (rate === 0) {
      return -(pv + fv) / nperiod;
    }

    let pvif = Math.pow(1 + rate, nperiod);
    let pmt = rate / (pvif - 1) * -(pv * pvif + fv);

    if (type === 1) {
      pmt /= (1 + rate);
    }
    return pmt;
  }
}

interface CalculatorProps {
  data: PcpData;
  onDataChange: (data: PcpData, field: string, value: any) => void;
}

const Calculator: React.FC<CalculatorProps> = (props) => {

  const data = props.data;
  const { register, errors } = useForm();

  const handleChange = (event: any) => {
    props.onDataChange(data, event.target.name, event.target.value);
  }

  const formatMoney = (num: number) => {
    if(num !== undefined){
      try{
        // return Number.parseFloat(num.toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}));
        return num.toFixed();
      }
      catch(e){
        console.log(e);
        return num;
      }
    }
    else{
      return num;
    }
  }


  return (
    <>
      <form>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>{data.name}</Card.Title>

            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control name="name" type="text" placeholder="Name" value={data.name} ref={register({
                validate: value => value !== "admin" || "Nice try!"
              })} onChange={handleChange} />
              {errors.name && errors.name.message}
            </Form.Group>

            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="price" type="number" placeholder="Price" value={data.price} ref={register({ required: true })} onChange={handleChange} />
                <Form.Control name="price" value={data.price} type="range" min={5000} max={150000} ref={register({ required: true })} onChange={handleChange} />
                {errors.price && errors.price.message}
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>Dealer Contribution</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="contribution" placeholder="Dealer Contribution" type="number" value={data.contribution} ref={register({ required: true })} onChange={handleChange} />
                <Form.Control name="contribution" value={data.contribution} type="range" min={0} max={data.price} ref={register({ required: true })} onChange={handleChange} />
                {errors.contribution && errors.contribution.message}
              </InputGroup>
            </Form.Group>


            <Form.Group>
              <Form.Label>Deposit</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="deposit" placeholder="Deposit" type="number" value={data.deposit} ref={register({ required: true })} onChange={handleChange} />
                <Form.Control name="deposit" value={data.deposit} type="range" min={0} max={data.price} ref={register({ required: true })} onChange={handleChange} />
                {errors.deposit && errors.deposit.message}
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="gfv">
              <Form.Label>Guaranteed Future Value (ballon payment)
                <OverlayTrigger
                  key="gfv"
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-gfv">
                      Sometimes called Optional Final Payment, Guaranteed Minimum Future Value (GMFV) or Balloon payment.
                      The price which you may choose to buy the car for at the end of the financing deal.
                    </Tooltip>
                  }>

                  <InfoCircle />
                </OverlayTrigger>
              </Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="gfv" type="number" value={data.gfv} ref={register({ required: true })} onChange={handleChange} />
                <Form.Control name="gfv" value={data.gfv} type="range" min={0} max={data.price} ref={register({ required: true })} onChange={handleChange} />
                {errors.gfv && errors.gfv.message}
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>Term (months)</Form.Label>
              <Form.Control name="term" placeholder="Term (months)" type="number" value={data.term} ref={register({ required: true })} onChange={handleChange} />
              <Form.Control name="term" value={data.term} type="range" min={0} max={60} ref={register({ required: true })} onChange={handleChange} />
              {errors.term && errors.term.message}
            </Form.Group>


            <Form.Group>
              <Form.Label>APR</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="apr" placeholder="APR" type="number" value={data.apr} ref={register({ required: true })} onChange={handleChange} />
                {errors.apr && errors.apr.message}
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>Part Exchange Valuation</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="pxValuation" placeholder="Part Exchange Valuation" type="number" value={data.pxValuation} ref={register({ required: true })} onChange={handleChange} />
                {errors.pxValuation && errors.pxValuation.message}
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>Part Exchange GFV</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="pxGFV" placeholder="Part Exchange GFV" type="number" value={data.pxGFV} ref={register({ required: true })} onChange={handleChange} />
                {errors.pxGFV && errors.pxGFV.message}
              </InputGroup>
            </Form.Group>

            <hr />
            <Form.Group>
              <Form.Label>Monthly Cost</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control readOnly type="number" placeholder=" Monthly Cost" value={formatMoney(data.getMonthlyCost())} />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Actual Monthly Cost
                <OverlayTrigger
                  key="actual-monthly-cost"
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-actual-monthly-cost">
                      Actual monhtly cost factoring in deposit<br />
                      monhtly cost + (your deposit / term) = <br />
                      {formatMoney(data.getMonthlyCost())} + ({data.deposit} / {data.term})
                    </Tooltip>
                  }>
                  <InfoCircle />
                </OverlayTrigger>
              </Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control readOnly type="number" placeholder=" Monthly Cost" value={formatMoney(data.getActualMonthlyCost())} />
              </InputGroup>
            </Form.Group>


            <Form.Group>
              <Form.Label>
                Total Term Cost
                  <OverlayTrigger
                  key="deposit"
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-deposit">
                      MonthlyCost*term+deposit = {formatMoney(data.getMonthlyCost())}*{data.term}+{data.deposit}
                      <br />
                      <b>Note:</b> does not include the equity from the part exchange.
                      </Tooltip>
                  }>

                  <InfoCircle />
                </OverlayTrigger>
              </Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control readOnly type="number" placeholder=" Total Term Cost" value={formatMoney(data.getTotalTermCost())} />
              </InputGroup>
            </Form.Group>


            <Form.Group>
              <Form.Label>
                Yearly Cost
                <OverlayTrigger
                  key="gfv"
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-gfv">
                      Simply the Total Term Cost divided by the number of years.<br />
                        Total Term Cost / (term/12) = {formatMoney(data.getTotalTermCost())} / ({data.term}/12)
                      </Tooltip>
                  }>
                  <InfoCircle />
                </OverlayTrigger>
              </Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control readOnly type="number" placeholder="Yearly Cost" value={formatMoney(data.getYearlyCost())} />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Financed Amount</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control readOnly type="number" placeholder="Financed Amount" value={data.getFinancedAmount()} />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>Deprecation Amount</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control readOnly type="number" placeholder="Deprecation" value={data.getDeprecationAmount()} />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Cash Amount
                  <OverlayTrigger
                  key="cash-amount"
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-cash-amount">
                      All the money paid upfront.
                      Dealer contribution + your deposit + equity in part exchange = <br />
                      {data.contribution} + {data.deposit} + {data.pxValuation - data.pxGFV}
                    </Tooltip>
                  }>
                  <InfoCircle />
                </OverlayTrigger>
              </Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control readOnly type="number" placeholder="Cash Part" value={data.getCashAmount()} />
              </InputGroup>
            </Form.Group>

          </Card.Body>
        </Card>

      </form>
    </>
  );
};

export default Calculator;
