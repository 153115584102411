import React from 'react';
import { Navbar, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Header: React.FC = () => {

  return (
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand as={Link} to="/" >
      PCP Compare
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/calc">Calculator</Nav.Link>
        <Nav.Link as={Link} to="/info">Information</Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default Header;
