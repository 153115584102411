import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
//import logo from './logo.svg';
import './App.css';
import PcpComparePage from './PcpComparePage';
import InfoPage from './InfoPage';
import { AppPage } from './AppPage';
import ReactGA from 'react-ga';

const App: React.FC = () => {
  useEffect(() => {
    if(process.env.NODE_ENV === 'production'){
      ReactGA.initialize('UA-97250262-1', {
        debug: false,
        titleCase: false
      });
      ReactGA.pageview(window.location.pathname);
    }
    else{
      console.log("In development, not initialising google analytics")
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Redirect from='/' to='/calc' exact />
        <Route path='/calc'>
          <AppPage component={PcpComparePage} />
        </Route>
        <Route path='/info'>
          <AppPage component={InfoPage} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
